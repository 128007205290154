<template>
    <section v-loading="loading" class="llamados-ver mt-4 ml-4">
        <div class="row mb-3">
            <div class="col-12">
                <el-breadcrumb separator-class="el-icon-arrow-right" class="f-300">
                    <el-breadcrumb-item :to="{ name: 'llamados.atencion.listado' }">
                        Llamados de atención
                    </el-breadcrumb-item>
                    <el-breadcrumb-item>Llamado</el-breadcrumb-item>
                </el-breadcrumb>
            </div>
        </div>
        <div class="row">
            <div class="col-12 col-lg-5">
                <div class="row">
                    <div class="col-auto my-auto">
                        <p class="f-20 f-500">{{ llamado.id }} - {{ llamado.titulo }}</p>
                    </div>
                    <div class="col-auto ml-auto my-auto">
                        <el-tooltip placement="bottom-end" effect="light" :visible-arrow="false" popper-class="border-0 shadow-sm br-3">
                            <div slot="content" class="text-86">
                                <p class="cr-pointer" @click="retirarLlamado">Retirar llamado</p>
                            </div>
                            <div class="mas-opciones d-middle-center br-4 f-15 cr-pointer ml-2">
                                <i class="icon-vertical-points" />
                            </div>
                        </el-tooltip>
                    </div>
                    <div class="col-12">
                        <p class="f-15">{{ llamado.descripcion }}</p>
                    </div>
                    <div class="col-12 mt-2 mt-lg-5">
                        <p class="f-15 f-500 mb-2">Recomendaciones</p>
                        <p class="f-15">{{ llamado.recomendaciones }}</p>
                    </div>
                    <div class="col-12 mt-2 mt-lg-5">
                        <p class="f-15 f-500 mb-2">Vivienda</p>
                        <div class="row">
                            <div class="col-12">
                                <div class="d-flex tres-puntos">
                                    <div class="wh-32px rounded-circle d-middle-center bg-azulclaro">
                                        <i class="icon-account-outline f-20 op-05" />
                                    </div>
                                    <p class="f-15 ml-2 my-auto tres-puntos"> {{ llamado.vivienda }} </p>
                                </div>
                            </div>
                            <div class="col-12 mt-3">
                                <p class="d-middle">
                                    <i class="icon-calendar mr-2 f-20" />
                                    <span class="f-17 my-auto">{{ llamado.created_at | helper-fecha('DD MMM YYYY') }}</span>
                                </p>
                            </div>
                            <div class="row mx-0 my-3">
                                <div class="col-auto">
                                    <img v-if="llamado.reaccion == 1" src="/img/pqr/enojadoAmarillo.svg" />
                                    <img v-if="llamado.reaccion == 2" src="/img/pqr/tristeAmarillo.svg" />
                                    <img v-if="llamado.reaccion == 3" src="/img/pqr/confuso-1.svg" />
                                    <img v-if="llamado.reaccion == 4" src="/img/pqr/felizAmarillo.svg" />
                                    <img v-if="llamado.reaccion == 5" src="/img/pqr/enamorado.svg" />
                                </div>
                                <div class="col-auto my-auto">
                                    <span class="f-17 my-auto">
                                        <span v-if="llamado.reaccion == 1">Enojado</span>
                                        <span v-if="llamado.reaccion == 2">Triste</span>
                                        <span v-if="llamado.reaccion == 3">Imparcial</span>
                                        <span v-if="llamado.reaccion == 4">Contento</span>
                                        <span v-if="llamado.reaccion == 5">Muy Feliz</span>
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!-- Con Vueper Slides -->
            <!-- <div class="col-12 col-lg-5 d-none"> -->
            <!-- <vueper-slides class="vueperslides--1" ref="vueperslides1" :touchable="false" fade="fade" :autoplay="false" :bullets="false" @slide="$refs.vueperslides2 && $refs.vueperslides2.goToSlide($event.currentSlide.index, { emit: false })" fixed-height="325px" :arrows="false">
                    <vueper-slide v-for="(slide, i) in llamado.imagenes" :key="i" :image="slide.imagen"> </vueper-slide>
                </vueper-slides> -->

            <!-- <vueper-slides class="no-shadow vueperslides--2 mt-3" ref="vueperslides2" @slide="$refs.vueperslides1 && $refs.vueperslides1.goToSlide($event.currentSlide.index, { emit: false })" :visible-slides="slider.data.length" fixed-height="87px" :bullets="false" :touchable="false" :gap="2.5" :arrows="false">
                    <vueper-slide v-for="(slide, i) in llamado.imagenes" :key="i" :image="slide.imagen" @click.native="$refs.vueperslides2 && $refs.vueperslides2.goToSlide(i)"></vueper-slide>
                </vueper-slides> -->
            <!-- </div> -->
            <!-- Componente -->
            <div class="col-12 col-lg-5">
                <galeria-cinco ref="gallriaRef" :listado="llamado.imagenes" />
            </div>
            <!-- Partials -->
            <modal-eliminar ref="modalRetirarLlamado" titulo="Retirar llamado" mensaje="¿Desea retirar el llamado?" tamano="modal-md" class="f-15 text-center" @eliminar="eliminar" />
        </div>
    </section>
</template>
<script>
import Llamados from '~/services/llamados'
export default {
    data(){
        return{
            slider:{
                data: [
                    { image: 'https://farm4.staticflickr.com/3364/3409068082_bbecd0b7cc_o.jpg' },
                    { image: 'https://combo.staticflickr.com/ap/build/images/sohp/2019-top-25/Ramon_Covelo_Sakrisoy%20Dreams.jpg' },
                    { image: 'https://combo.staticflickr.com/ap/build/images/sohp/2019-top-25/Alex_Noriega_Pure_Magic.jpg' },
                    { image: 'https://combo.staticflickr.com/ap/build/images/sohp/2019-top-25/Ramon_Covelo_Sakrisoy%20Dreams.jpg' },
                    { image: 'https://combo.staticflickr.com/ap/build/images/sohp/2019-top-25/Alex_Noriega_Pure_Magic.jpg' },
                ]
            },
            listadoImagenes: [
                { id: 0,img: 'https://farm4.staticflickr.com/3364/3409068082_bbecd0b7cc_o.jpg' },
                { id: 1,img: 'https://combo.staticflickr.com/ap/build/images/sohp/2019-top-25/Ramon_Covelo_Sakrisoy%20Dreams.jpg' },
                { id: 2,img: 'https://combo.staticflickr.com/ap/build/images/sohp/2019-top-25/Alex_Noriega_Pure_Magic.jpg' },
                { id: 3,img: 'https://combo.staticflickr.com/ap/build/images/sohp/2019-top-25/Ramon_Covelo_Sakrisoy%20Dreams.jpg' },
                { id: 4,img: 'https://combo.staticflickr.com/ap/build/images/sohp/2019-top-25/Alex_Noriega_Pure_Magic.jpg' },
            ],
            imgSeleccionada: null,
            imgSeleccionadaSrc: '/img/noImg/anuncio.svg',

            loading: false,
            llamado: {
                titulo: '',
                imagenes: []
            },
            imagenes:[]
        }
    },
    created(){
        this.mostrar()
    },
    methods:{
        
        retirarLlamado(){
            this.$refs.modalRetirarLlamado.toggle()
        },
        async mostrar(){
            try {
                this.loading = true
                const {data} = await Llamados.mostrar(this.$route.params.id_llamado)
                this.loading = false
                if(data.success){
                    this.llamado = data.data
                    this.llamado.imagenes.forEach(imagen => {
                        imagen.img = imagen.imagen
                    });
                    this.imagenes = this.llamado.imagenes
                    this.$refs.gallriaRef.seleccionarImg(this.imagenes[0].id,this.imagenes[0].img)
                }
            } catch (e){
                this.loading = false
                console.log(e)
            }
        },
        async eliminar(){
            try {
                this.loading = true
                let params = {
                    id_llamado : this.$route.params.id_llamado
                }
                const {data} = await Llamados.eliminar(params)
                this.loading = false
                if(data.success){
                    this.$notify({
                        title: 'Eliminar',
                        message: data.mensaje,
                        type: 'success'
                    });
                    this.$refs.modalRetirarLlamado.toggle()
                    this.$router.push({ name: 'llamados.atencion.listado' })

                }else{
                    this.$notify({
                        title: 'Eliminar',
                        message: data.mensaje,
                        type: 'warning'
                    });
                }
            } catch (e){
                this.loading = false
                console.log(e)
            }
        }

    }
}
</script>
<style lang="scss" scoped>
.llamados-ver{
    .vueperslides--2{
        .vueperslide{
            cursor: pointer;
        }
        .vueperslide--active{
            border: 3px solid #109881;
            border-radius: 4px;
        }
    }
    .vueperslide, .vueperslides__parallax-wrapper{
        border-radius: 4px !important;
    }
    .vueperslides__inner .vueperslide.vueperslide--active.vueperslide--visible .vueperslide__content-wrapper{
        border: 3px solid white;
    }
}
</style>